import { GroupsClient } from '../clients/groups/groups-client';
import {
    GroupMemberStatus,
    IGroup,
    IGroupBasic,
    IPagedResult,
} from '../clients/groups/groups-model';
import { ITabGroupData, IGroupCompliance } from '../components/tab/tab-models';
import { IAuthContext } from '../contexts/auth-context';
import { PersonnelService } from './personnel-service';

export class GroupService {
    private _authContext: IAuthContext;
    private _personnelService: PersonnelService;

    private _groupIdToGroupDataMap: { [key: string]: ITabGroupData } = {};
    private _graphUserIdToGroupCompliance: { [key: string]: IGroupCompliance } = {};
    private _userCompliantGroupIds?: string[] = undefined;

    public constructor(authContext: IAuthContext, personnelService: PersonnelService) {
        this._authContext = authContext;
        this._personnelService = personnelService;
    }

    public async getCompliantGroupsForUser(): Promise<IGroupBasic[]> {
        if (!this._userCompliantGroupIds) {
            this._userCompliantGroupIds = [];

            let continuationToken: string | undefined = undefined;
            let result: IPagedResult<IGroup>;
            do {
                result = await GroupsClient.getMyGroups(
                    this._authContext,
                    false,
                    continuationToken,
                    [GroupMemberStatus.Approved],
                );

                result.results.forEach((group) => {
                    this._userCompliantGroupIds?.push(group.id);

                    this._groupIdToGroupDataMap[group.id] = {
                        group: group,
                        isCompliantMember: true,
                    };
                });

                continuationToken = result.continuationToken;
            } while (!!continuationToken);
        }

        return this._userCompliantGroupIds.map(
            (groupId) => this._groupIdToGroupDataMap[groupId].group,
        );
    }

    public async getGroupData(groupId: string): Promise<ITabGroupData> {
        if (!(groupId in this._groupIdToGroupDataMap)) {
            try {
                const group = await GroupsClient.getGroup(this._authContext, groupId);

                this._groupIdToGroupDataMap[groupId] = {
                    group: group,
                    isCompliantMember: true,
                };
            } catch (e) {
                try {
                    const group = await GroupsClient.getGroupBasic(this._authContext, groupId);

                    this._groupIdToGroupDataMap[groupId] = {
                        group: group,
                        isCompliantMember: false,
                    };
                } catch (e) {
                    console.log('Failed to get group basic', e);
                    throw e;
                }
            }
        }

        return this._groupIdToGroupDataMap[groupId];
    }

    public getExistingGroupData(groupId: string): ITabGroupData {
        if (groupId in this._groupIdToGroupDataMap) {
            return this._groupIdToGroupDataMap[groupId];
        }
        throw new Error(`Do not have group data for groupId ${groupId}`);
    }

    public async setGroupsComplianceData(
        groupIds: string[],
        graphUserIds: string[],
    ): Promise<void> {
        await Promise.all(
            groupIds.map((groupId) => {
                return this.setGroupComplianceData(groupId, graphUserIds);
            }),
        );
    }

    public async setGroupComplianceData(groupId: string, graphUserIds: string[]): Promise<void> {
        const missingGraphUserIds = graphUserIds.filter((graphUserId) => {
            return (
                !(graphUserId in this._graphUserIdToGroupCompliance) ||
                !(groupId in this._graphUserIdToGroupCompliance[graphUserId])
            );
        });

        const missingPersonnelIds =
            this._personnelService.getExistingPersonnelIdsFromGraphUserIds(missingGraphUserIds);

        if (missingPersonnelIds.length > 0) {
            const compliantMemberPersonnelIds = await GroupsClient.checkGroupMemberships(
                this._authContext,
                groupId,
                missingPersonnelIds,
            );

            missingGraphUserIds.forEach((graphUserId) => {
                const personnelId =
                    this._personnelService.getExistingPersonnelIdFromGraphUserId(graphUserId);

                if (personnelId) {
                    const isCompliantMember = compliantMemberPersonnelIds.includes(personnelId);

                    if (!(graphUserId in this._graphUserIdToGroupCompliance)) {
                        this._graphUserIdToGroupCompliance[graphUserId] = {};
                    }

                    this._graphUserIdToGroupCompliance[graphUserId][groupId] = isCompliantMember;
                }
            });
        }
    }

    public getExistingGroupComplianceDataForUser(
        graphUserId: string,
        groupIds: string[],
    ): IGroupCompliance {
        const allGroupCompliances = this._graphUserIdToGroupCompliance[graphUserId] || {};
        const groupCompliance: IGroupCompliance = {};

        for (const groupId of groupIds) {
            groupCompliance[groupId] = allGroupCompliances[groupId] || false;
        }

        return groupCompliance;
    }
}
